@use '@angular/material' as mat;
@import "libs/shared/styles/reset";
@import "libs/shared/styles/utiles";
@import "libs/shared/styles/fonts/fonts";
@import "libs/shared/styles/common";
@import "libs/shared/styles/elements/elements";
@import "libs/shared/styles/animations/animations";
@import "libs/shared/styles/override/override";
@import "libs/shared/styles/material-palette";
@import '~@ng-select/ng-select/themes/default.theme.css';
@import "libs/shared/styles/override/flock";
@import "libs/shared/styles/override/testing-dialog";

@include mat.core();

.disabled {
  pointer-events: none;
}
