.popup-message {
  width: rem(350);
  max-width: 95vw;
  padding: rem(20);

  &__icon {
    width: rem(48);
    height: rem(48);
  }
}

.popup {
  padding: rem(20);

  &-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.date-time-keyboard {
  position: fixed;
  top: 50%;
  right: 50px;
  z-index: 10000000;
  transform: translateY(-50%);

  &.rtl {
    left: 50px;
    right: auto;
  }
}

.mobile-content-width {
  @include media('md') {
    width: calc(100vw - 32px)!important;
  }
}

ls-add-device-dialog {
  ls-input {
    input {
      &::placeholder {
        color: $tertiary-color;
        opacity: .5;
      }
    }
  }
}
