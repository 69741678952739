/*common styles for colors*/
@import 'css-properties/colors';
/*padding and margin*/
@import 'css-properties/margins';
/*font*/
@import 'css-properties/font';
/*FLEX*/
@import 'css-properties/flex';

@include media('md') {
  html, body {
    height: 100%;
  }
}

body {
  font-family: $font;
  font-weight: 400;
  color: color(mono-dark);
  line-height: 1;
  background: color(mono-bg);
}

/*common*/
.d-block {
  display: block;
}

.d-none {
  display: none;
}

.pointer-events-none {
  cursor: default;
  pointer-events: none;
}

.overflow-hidden {
  overflow: hidden;
}

@include media('device') {
  .d-none-device {
    display: none;
  }

  .d-block-device {
    display: block;
  }
}

@include media('md') {
  .d-none-md {
    display: none;
  }

  .d-block-md {
    display: block;
  }

  .d-flex-md {
    display: flex;
  }
}

@include media('min-md') {
  .d-none-min-md {
    display: none;
  }
}

@include media('mobile') {
  .d-none-mobile {
    display: none;
  }

  .d-block-mobile {
    display: block;
  }
}

.pointer {
  cursor: pointer;
}

.rtl {
  direction: rtl;

  mat-dialog-container {
    direction: rtl;
  }
}

/*positioning*/
.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.left-50-percent {
  left: 50%;
  transform: translateX(-50%);
}

.top-50-percent {
  top: 50%;
  transform: translateY(-50%);
}

.left-top-50-percent {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bottom-0 {
  bottom: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-12 {
  right: rem(12);
}

.left-12 {
  left: rem(12);
}

.hidden {
  visibility: hidden;
  position: absolute;
}

/*width and height*/
$percents: 6, 11, 13, 19, 20, 30, 32, 33, 40, 50, 60, 66, 70, 75, 80, 85, 100;
$widthHeightRem: 22, 32, 36, 48, 56, 60, 70, 88, 90, 93, 97, 100, 105, 108, 110, 114, 120, 130, 135, 138, 140, 148, 150, 155, 158, 180, 200, 270, 305, 324, 330, 335, 360, 400;
@each $percent in $percents {
  .w-#{$percent}-percent {
    width: #{$percent}#{'%'};
  }

  .w-#{$percent}-percent-forced {
    width: #{$percent}#{'%'} !important;
  }

  .h-#{$percent}-percent {
    height: #{$percent}#{'%'};
  }

  @include media('mobile') {
    .w-#{$percent}-percent-mobile {
      width: #{$percent}#{'%'};
    }

    .h-#{$percent}-percent-mobile {
      height: #{$percent}#{'%'};
    }
  }
}

@each $rem in $widthHeightRem {
  .w-#{$rem} {
    width: rem($rem);
  }

  .h-#{$rem} {
    height: rem($rem);
  }
}

.w-90-vw {
  width: 90vw!important;
}

.w-100-vw {
  width: 100vw;
}

.h-100-vh {
  height: 100vh;
}

.h-auto {
  height: auto;
}

.w-mc {
  width: max-content;
}

.w-auto {
  width: auto;
}

.min-width-unset {
  min-width: unset;
}

@include media('md') {
  .w-auto-md {
    width: auto;
  }
}

@include media('mobile') {
  .w-auto-mobile {
    width: auto;
  }
}

/*opacity*/
.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.7;
}

a {
  color: color(primary);
}

img {
  max-width: 100%;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-none {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.alert-border {
  border: 1px solid color(alert);
}

.user-select-none {
  user-select: none;
}

.min-height-0 {
  min-height: 0;
}

.vertical-align-top {
  vertical-align: top !important;
}

.ltr {
  direction: ltr!important;
}

.rtl {
  .form-field {
    &__chevron {
      transform: rotate(180deg);
    }
  }
}

.break-word {
  word-break: break-word;
}

.rotate-180 {
  transform: rotate(180deg);
}
