.heater, .sensor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: rem(40);
  height: rem(40);
  border-radius: 4px;
  border: 1px solid color(color-d0);
  padding-top: rem(5);
  margin-bottom: rem(3);
  margin-right: rem(3);

  ls-svg-icon {
    margin-bottom: 0;
    width: rem(20);
    height: rem(20);
  }
}

.heater {
  &-active {
    border: 2px solid color(active);
    background: color(light-blue);
  }
}

.sensor {
  &-all {
    background: color(mono-bg);
  }
}

@media screen and (min-width: 1281px) {
  .controller-menu__item-with__message {
    &:hover {
      svg {
        path, rect {
          fill: color(white);
        }
      }
    }
  }
}

@include media('device') {
  .hover-background {
    svg {
      path, rect {
        fill: color(white);
      }
    }
  }
}
