.dot {
  width: rem(8);
  height: rem(8);
  border-radius: 50%;
  background-color: color(color-d0);

  &-active {
    background-color: color(primary);
  }

  &-active__menu {
    background-color: color(white);
  }
}

// programs
.swiper-container {
  .swiper-wrapper {
    &.swiper-wrapper__hide-pagination {
      height: 100%;

      .swiper-pagination {
        display: none;
      }
    }
    .swiper-pagination {
      bottom: 50px;

      .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: color(primary);
      }
    }
  }
}

.swiper-arrows {
  &.swiper-navigation {
    position: absolute;
    right: 0;
    top: -8px;
    z-index: 99;
    width: 80px;
    height: 55px;

    .swiper-navigation-prev,
    .swiper-navigation-next {
      position: absolute;
      top: 50%;
      border: solid color(color-61);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 6px;

      &.swiper-button-disabled {
        border-color: color(gray-disabled);
      }
    }

    .swiper-navigation-prev {
      left: 0;
      transform: translateY(-50%) rotate(135deg);
      margin-right: 10px;
    }

    .swiper-navigation-next {
      right: 0;
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}

.time-picker {
  &__slide-next,
  &__slide-prev {
    opacity: 0.5;
  }

  &__slide-next {
    border-top: 3px solid color(color-61);
  }

  &__slide-prev {
    border-bottom: 3px solid color(color-61);
  }
}

.swiper-navigation {
  &__w-70-percent {
    left: 0;
    width: 70%;
  }
}

.rtl {
  .swiper-arrows {
    &.swiper-navigation {
      right: auto;
      left: 0;
    }
  }

  .swiper-navigation {
    &__w-70-percent {
      left: auto;
      right: 0;
    }
  }
}

//menu
.controller-menu {
  .swiper-pagination-bullet {
    background-color: color(color-d0);
  }

  .swiper-pagination-bullet-active {
    background-color: color(white);
  }

  @include media('md') {
    .swiper-slide {
      padding: 0 rem(45);
    }
  }

  @media screen and (max-width: 374px) {
    .swiper-slide {
      padding: 0 rem(25);
    }
  }
}

.rtl {
  .swiper-title {
    text-align: right;
  }
}
