.mat-calendar {
  border: 1px solid color(color-d0);
  border-radius: 6px;

  .mat-calendar-body-cell-content {
    font-size: rem(20);
  }

  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base,
  .mat-mdc-outlined-button.mat-mdc-button-base {
    border: 1px solid color(color-d0);
    border-radius: 4px;
  }
}
