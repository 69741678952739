.mat-mdc-slider {
  margin: 0!important;
}

.mdc-slider__thumb-knob {
  border: 2px solid color(main-blue)!important;
  background-color: color(white)!important;
  height: 24px !important;
  width: 18px !important;
  border-radius: 100px !important;
  box-sizing: border-box !important;
}

.mdc-slider__track,
.mdc-slider__track--inactive,
.mdc-slider__track--active {
  height: 8px!important;
  opacity: 1!important;
}

.mdc-slider__track--active {
  top: 0!important;
}

.mat-mdc-slider .mdc-slider__track--inactive {
  background-color: color(mono-bordergrey)!important;
}

.mat-mdc-slider .mdc-slider__track--active_fill {
  background-color: color(main-blue)!important;
  border-color: color(main-blue)!important;
}

.mat-mdc-slider .mat-ripple .mat-mdc-slider-focus-ripple,
.mat-mdc-slider .mat-ripple .mat-mdc-slider-active-ripple {
  background-color: rgba(color(main-blue), 0.2)!important;
}
