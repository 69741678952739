$scrollbar-width: 6px;

@mixin styled-scroll($color: color(color-d0)) {
  &::-webkit-scrollbar {
      height: 6px;
      width: $scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
      background-color: $color;
      border-radius: 3px;
  }

  scrollbar-width: thin;
}

.thin-scrollbar {
  @include styled-scroll;
}
