.d-flex {
  display: flex;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-around {
  justify-content: space-around;
}

.justify-unset {
  justify-content: unset !important;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.align-self-end {
  align-self: end;
}

.flex-1 {
  flex: 1;
}

//desktop - 1899px max
@include media('desktop') {
  .d-flex-desktop {
    display: flex;
  }

  .justify-start-desktop {
    justify-content: flex-start;
  }

  .justify-center-desktop {
    justify-content: center;
  }

  .justify-end-desktop {
    justify-content: flex-end;
  }

  .justify-between-desktop {
    justify-content: space-between;
  }

  .align-center-desktop {
    align-items: center;
  }

  .align-end-desktop {
    align-items: flex-end;
  }

  .align-start-desktop {
    align-items: flex-start;
  }

  .flex-wrap-desktop {
    flex-wrap: wrap;
  }

  .flex-column-desktop {
    flex-direction: column;
  }

  .flex-row-desktop {
    flex-direction: row;
  }

  .row-reverse-desktop {
    flex-direction: row-reverse;
  }

  .flex-grow-1-desktop {
    flex-grow: 1;
  }
}

//xl - 1599px max
@include media('xl') {
  .d-flex-xl {
    display: flex;
  }

  .justify-start-xl {
    justify-content: flex-start;
  }

  .justify-center-xl {
    justify-content: center;
  }

  .justify-end-xl {
    justify-content: flex-end;
  }

  .justify-between-xl {
    justify-content: space-between;
  }

  .align-center-xl {
    align-items: center;
  }

  .align-end-xl {
    align-items: flex-end;
  }

  .align-start-xl {
    align-items: flex-start;
  }

  .flex-wrap-xl {
    flex-wrap: wrap;
  }

  .flex-column-xl {
    flex-direction: column;
  }

  .flex-row-xl {
    flex-direction: row;
  }

  .row-reverse-xl {
    flex-direction: row-reverse;
  }

  .flex-grow-1-xl {
    flex-grow: 1;
  }
}

//device - 1280px max
@include media('device') {
  .d-flex-device {
    display: flex;
  }

  .justify-start-device {
    justify-content: flex-start;
  }

  .justify-center-device {
    justify-content: center;
  }

  .justify-end-device {
    justify-content: flex-end;
  }

  .justify-between-device {
    justify-content: space-between;
  }

  .align-center-device {
    align-items: center;
  }

  .align-end-device {
    align-items: flex-end;
  }

  .align-start-device {
    align-items: flex-start;
  }

  .flex-wrap-device {
    flex-wrap: wrap;
  }

  .flex-column-device {
    flex-direction: column;
  }

  .flex-row-device {
    flex-direction: row;
  }

  .row-reverse-device {
    flex-direction: row-reverse;
  }

  .flex-grow-1-device {
    flex-grow: 1;
  }
}

//mobile - 575px max
@include media('mobile') {
  .d-flex-mobile {
    display: flex;
  }

  .justify-start-mobile {
    justify-content: flex-start;
  }

  .justify-center-mobile {
    justify-content: center;
  }

  .justify-end-mobile {
    justify-content: flex-end;
  }

  .justify-between-mobile {
    justify-content: space-between;
  }

  .align-center-mobile {
    align-items: center;
  }

  .align-end-mobile {
    align-items: flex-end;
  }

  .align-start-mobile {
    align-items: flex-start;
  }

  .flex-wrap-mobile {
    flex-wrap: wrap;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .flex-row-mobile {
    flex-direction: row;
  }

  .row-reverse-mobile {
    flex-direction: row-reverse;
  }

  .flex-grow-1-mobile {
    flex-grow: 1;
  }
}

//mobile - 767px max
@include media('md') {
  .d-flex-md {
    display: flex;
  }

  .justify-start-md {
    justify-content: flex-start;
  }

  .justify-center-md {
    justify-content: center;
  }

  .justify-end-md {
    justify-content: flex-end;
  }

  .justify-between-md {
    justify-content: space-between;
  }

  .align-center-md {
    align-items: center;
  }

  .align-end-md {
    align-items: flex-end;
  }

  .align-start-md {
    align-items: flex-start;
  }

  .flex-wrap-md {
    flex-wrap: wrap;
  }

  .flex-column-md {
    flex-direction: column;
  }

  .flex-row-md {
    flex-direction: row;
  }

  .row-reverse-md {
    flex-direction: row-reverse;
  }

  .flex-grow-1-md {
    flex-grow: 1;
  }
}

$percents: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45,
  50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@each $percent in $percents {
  .flex-#{$percent} {
    flex-grow: $percent;
    flex-basis: 0;
  }
}

@include media('mobile') {
  @each $percent in $percents {
    .flex-#{$percent}-mobile {
      flex-grow: $percent;
      flex-basis: 0;
    }
  }
}