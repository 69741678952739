.page-content {
  height: calc(100svh - $topHeight - 8px - 34px); // 8 - top; 34 - bottom
  overflow: auto;
  padding: rem(30);

  @include media('device') {
    padding: rem(18) rem(30) rem(18) rem(30);
  }

  @include media('lg') {
    padding: rem(13) rem(16) rem(13) rem(16);
  }

  @include media('min-md-max-laptop') {
    padding: rem(13) rem(15) rem(13) rem(15);
  }

  @include media('md') {
    height: 100%;
    min-height: auto;
  }
}
