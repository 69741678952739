ls-heating-programs-form {
    .show-mode {
        input {
            border: none;
        }
    }

    .table-cell {
        padding: rem(20) !important;

        .error-field {
            bottom: 0;
        }
    }

    input {
        max-width: 130px !important;
        width: 130px !important;
    }

    @include media("device") {
        input {
            max-width: 120px !important;
            width: 120px !important;
        }
    }

    @include media("md") {
        input {
            max-width: 170px !important;
            width: 170px !important;
        }
    }
}

ls-heating-programs {
    @include media('md') {
        .content-wrapper__header-container {
            align-items: start !important;
        }
    }
}