$columns: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
$percents: 5, 7, 8, 10, 12, 15, 16, 18, 20, 23, 25, 30, 33, 35, 40, 45, 46, 50, 60, 65, 67, 75;
$paddings: 5, 10;

.table {
  width: 100%;
  color: $secondary-color;
  line-height: rem(20);

  &.color-tertiary {
    color: $tertiary-color;
  }

  // w-1-50 w-2-25 w-3-25 => first column 50%, second and third - 25% width
  @each $column in $columns {
    @each $percent in $percents {
      &.w-#{$column}-#{$percent} {
        .table-row {
          .table-cell:nth-child(#{$column}) {
            width: #{$percent}#{'%'};
          }
        }
      }
    }
  }

  // equal columns
  @each $column in $columns {
    &.equal-columns-#{$column} {
      .table-row {
        .table-cell {
          width: calc(100% / #{$column});
        }
      }
    }
  }

  @each $padding in $paddings {
    &.padding-top-#{$padding} {
      .table-cell:not(.table-cell__full-input) {
        padding: #{$padding}#{'px'};
      }
    }
  }

  &-row {
    display: flex;

    &__header {
      .table-cell {
        padding: rem(10);
      }
    }

    &.active {
      border: 1px solid color(color-90);
    }

    .table-cell {
      border-top: 1px solid color(table-border);
      border-left: 1px solid color(table-border);
      position: relative;

      &:last-child {
        border-right: 1px solid color(table-border);
      }
    }

    &.border-bottom-none {
      .table-cell {
        border-bottom: none;
      }
    }

    &:last-child {
      .table-cell {
        border-bottom: 1px solid color(table-border);
      }

      &.border-bottom-none {
        .table-cell {
          border-bottom: none;
        }
      }
    }

    .table-cell.empty {
      border-top: none;
    }

    @each $padding in $paddings {
      &.padding-top-#{$padding} {
        .table-cell:not(.table-cell__full-input) {
          padding: #{$padding}#{'px'};
        }
      }
    }

    &.selected {
      .table-cell:not(.active-cell) {
        border-top: 1px solid color(color-90);
        border-bottom: 1px solid color(color-90);
      }

      .table-cell:first-child {
        border-left: 1px solid color(color-90);
      }

      .table-cell:last-child {
        border-right: 1px solid color(color-90);
      }

      .active-cell, .active-cell:last-child {
        border: 1px solid color(primary);
      }
    }

    &.gray {
      .table-cell {
        background-color: color(color-f9);
      }
    }
  }

  &-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: rem(10) rem(8);

    @include media('xl') {
      padding: rem(10) rem(4);
    }

    @include media('md') {
      align-items: center;
      text-align: center;
      min-height: 60px;
      padding: rem(17) rem(5);
      background: color(white);
    }

    @each $percent in $percents {
      &.w-#{$percent} {
        width: #{$percent}#{'%'};
      }
    }

    &.blue {
      background-color: color(table-header) !important;
    }

    &.gray {
      background-color: color(color-f9) !important;
    }

    &.light-blue {
      background-color: color(table-header);
    }

    &.justify-start {
      justify-content: flex-start;
    }

    @each $padding in $paddings {
      &.padding-top-#{$padding} {
        padding: #{$padding}#{'px'};
      }
    }

    &.border-left-bold {
      border-left: 2px solid color(color-90);
    }

    &__full-input {
      padding: 0;

      ls-input-integer, ls-input-decimal {
        width: 100%!important;

        input {
          border-radius: 0;
          max-width: none;
          text-align: center;
          color: $tertiary-color;
          border: 1px solid transparent;

          &:focus,
          &:focus-visible {
            border: 1px solid color(primary);
          }
        }
      }
    }
  }

  &-header {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 10;

    .table-cell {
      background-color: color(table-header);

      @include media('md') {
        background-color: color(table-header-mobile);
      }
    }
  }
}

.rtl {
  .table {
    &-row {
      .table-cell {
        border-right: 1px solid color(table-border);
        border-left: none;

        &:last-child {
          border-right: 1px solid color(table-border);
          border-left: 1px solid color(table-border);
        }
      }

      &.selected {
        .table-cell:not(.active-cell) {
          border-top: 1px solid color(color-90);
          border-bottom: 1px solid color(color-90);
        }

        .table-cell:first-child {
          border-right: 1px solid color(color-90);
          border-left: 1px solid color(color-90);
        }

        .table-cell:last-child {
          border-right: 1px solid color(color-90);
          border-left: 1px solid color(color-90);
        }
      }
    }

    &-cell {
      &.border-left-bold {
        border-left: none;
        border-right: 2px solid color(color-90);
      }
    }
  }
}

.rtl {
  .table {
    &-row {
      direction: rtl;
    }
  }
}
