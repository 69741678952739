input {
  font-family: $font;
}

ls-web-quick-start,
ls-controller-general-settings {
  ls-input {

    input,
    .alert {
      border: none !important;
      padding: 0 !important;
    }

    .error {
      display: inline-block;
      margin: rem(10) 0 0 rem(-13);
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.field-title.required::after {
  content: "*";
  color: color(danger);
}

ls-house-sizes-settings-form {
  ls-input-decimal {
    input {
      border-radius: 4px !important;
      border: 1px solid color(mono-grey);
      color: color(color-61) !important;
      font-size: rem(16) !important;
      font-weight: 500 !important;

      &:focus, &:focus-within {
        border: 1px solid color(main-blue);
      }
    }
  }
}

.form-field__login ls-input.ng-invalid.ng-dirty input {
  border-color: color(danger) !important;

  &:focus,
  &:focus-visible {
    border-color: color(danger) !important;
  }
}

.bird-weight ls-input-integer.ng-invalid input,
.bird-weight ls-input-decimal.ng-invalid input,
{
border: 1px solid color(danger) !important;
color: color(alert) !important;
;

&+span {
  color: color(alert) !important;
  ;
}

&:focus,
&:focus-visible {
  border: 1px solid color(danger) !important;
  color: color(alert) !important;
  ;
}
}


ls-input-integer.align-center,
ls-input-decimal.align-center {
  input {
    text-align: center !important;
  }
}

ls-input-integer.w-100-percent,
ls-input-decimal.w-100-percent {
  input {
    max-width: none;
  }
}

ls-input-integer.border-none__no-editing-mode,
ls-input-decimal.border-none__no-editing-mode {
  input {
    border: none;
  }

  input[disabled] {
    opacity: 1;
  }
}

.ls-search ls-input-text {
  input {
    border: none !important;
  }
}

ls-input-integer.invalid,
ls-input-decimal.invalid {
  input {
    border-color: color(alert) !important;
    border-width: 1px;
  }
}

// error tooltips for inputs
.error-tooltip {
  padding: rem(2) rem(5);
  position: absolute;
  bottom: calc(100% + 5px);
  width: 100%;
  background: color(error-tooltip);
  color: color(white);
  font-size: rem(12);
  text-align: center;
  border-radius: 8px;
  z-index: 9999;
  line-height: 1.2;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -8px;
    border: 4px solid transparent;
    border-top: 4px solid color(error-tooltip);
  }
}

$widthRem: 40, 45, 60, 63, 70, 80, 100, 113, 115, 120, 130, 135, 154;
@each $rem in $widthRem {
  .w-input-#{$rem} {
    input {
      max-width: rem($rem) !important;
      width: rem($rem) !important;
    }
  }
}

ls-ventilation-brand-dialog {
  .ls-search-input {
    border-color: color(color-d0) !important;
  }
}

.program-table {

  ls-input-decimal,
  ls-input-integer {
    input {
      padding: 0 !important;
    }
  }
}

ls-cooling-program,
ls-sprinklers-foggers-program {

  ls-input-integer,
  ls-input-decimal {
    input.rtl {
      text-align: center !important;
    }
  }
}

.pseudo-input {
  padding: rem(6) rem(12);
  color: $primary-color;
  text-align: left;
  width: 100%;
  font-size: inherit;
  background-color: color(white);
  border-radius: 4px;
  border: 2px solid color(color-d0);
  outline: none;
}

ls-m-auth {
  lv-input {
    .ls-input {
      height: 48px !important;
    }
  }
}

ls-input-decimal {
  input {
    & + .show-on-focus {
      position: absolute;
      bottom: -15px;
      display: none;
    }

    & + div + .hide-on-focus {
      display: block;
    }
  }

  input:focus, input:focus-within {
    & + .show-on-focus {
      display: block;
      color: color(mono-dark);
    }

    & + div + .hide-on-focus {
      display: none;
    }
  }

  &.ng-invalid:not(.hide-error) {
    input {
      & + .show-on-focus {
        display: block;
        color: color(error);
      }

      & + div + .hide-on-focus {
        display: none;
      }
    }
  }
}


lv-input-decimal-with-label,
lv-input-integer-with-label {
    @include media('md') {
        .ls-input {
            .input-wrapper {
                input {
                    padding: 0;
                }

                input:focus+.input-label,
                .input-label_filled {
                    left: 14px;
                }
            }
        }
    }
}


ls-web-auth,
ls-m-auth {
  lv-input {
    display: block !important;

    .input-wrapper {
      padding: 0 0 0 rem(8);
    }

    input:focus+.input-label,
    .input-label_filled {
      display: none;
    }

    input[type='password'] {
      font-size: rem(34) !important;
    }
  }
}

.input-description {
  margin-top: 5px;
  font-size: rem(12);
  font-weight: 500;
}

.input-error {
  margin-top: 5px;
  font-size: rem(12);
  font-weight: 500;
  color: color(error);
}

lv-input {
  input {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

mat-slider {
  mat-slider-visual-thumb {
    height: 16px !important;
  }

  .mat-ripple {
    display: none;
  }
}

lv-input-time.bg-white {

    .input-wrapper {
      .input-label_filled {
        background: color(white) !important;
      }
    }

    .main-input-container {
      background-color: color(white) !important;

      .ls-input {
        background-color: color(white) !important;
      }
    }
}
