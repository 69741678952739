.widget {
  &-wrapper {
    border: 3px solid transparent;
  }

  &-active {
    .widget-wrapper {
      border: 3px solid color(active);
    }
  }
}
