.pseudo-loading {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  animation: hideAfterDelay .5s forwards;
}
